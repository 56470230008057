export const parseCookies = (cookies) => {
  return cookies.split(";").reduce((acc, cookie) => {
    const [key, value] = cookie.split("=").map((c) => c.trim());
    acc[key] = value;
    return acc;
  }, {});
};

export const getCookie = (key) => {
  return parseCookies(document.cookie)[key];
};

export const setCookie = (key, value, options = {}) => {
  let cookieString = `${key}=${value};`;

  if (options.expires) {
    const expires = new Date();
    expires.setDate(expires.getDate() + options.expires);
    cookieString += ` expires=${expires.toUTCString()};`;
  }

  if (options.maxAge) cookieString += ` max-age=${options.maxAge};`;

  if (options.path) {
    cookieString += ` path=${options.path};`;
  } else {
    cookieString += ` path=/;`;
  }

  if (options.sameSite) cookieString += ` SameSite=${options.sameSite};`;

  if (options.secure) cookieString += ` Secure;`;

  document.cookie = cookieString;
};

export const removeCookie = (key, options = {}) => {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; ${Object.entries(options)
    .map(([key, value]) => `${key}=${value}`)
    .join("; ")}`;
};
